import React from 'react';
import { Helmet } from 'react-helmet';

import ErrorLayout from 'components/ErrorLayout';

const ServerErrorPage = () => (
  <ErrorLayout code="500">
    <Helmet bodyAttributes={{ class: 'page-error page-error-500' }}>
      <title>500: Error</title>
    </Helmet>
    <h2>Whoops! Looks like something went wrong.</h2>
    <p>Try refreshing the page.</p>
  </ErrorLayout>
);

export default ServerErrorPage;
